import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "gatsby";
import styles from "./menu.module.css";
import MultiStepFormButton from "./MultiStepForm/MultiStepFormButton";

const Menu = ({ className }) => (
  <ul className={`${styles.menu} ${className}`}>
    <li className={styles.dropdown}>
      <Link to="/funkcje-systemu-crm/">
        Funkcje <FaAngleDown />
      </Link>
      <div className={styles.dropdownContent}>
        <ul className={styles.dropdownMenu}>
          <li>
            <Link title="Zarządzanie szansami sprzedaży" to="/sprzedaz-w-crm/">
              Zarządzanie szansami sprzedaży
            </Link>
          </li>
          <li>
            <Link title="Analiza działań sprzedaży" to="/raporty/">
              Analiza działań sprzedaży
            </Link>
          </li>
          <li>
            <Link title="Baza wiedzy o klientach" to="/kartoteki-kontrahentow/">
              Baza wiedzy o klientach
            </Link>
          </li>
          <li>
            <Link
              title="Planowanie pracy zespołu sprzedaży"
              to="/kalendarz-i-zadania/"
            >
              Planowanie pracy zespołu sprzedaży
            </Link>
          </li>
          <li>
            <Link title="Dynamiczny pulpit" to="/pulpit-pracownika/">
              Dynamiczny pulpit
            </Link>
          </li>
          <li>
            <Link title="Komunikacja w zespole" to="/komunikator/">
              Komunikacja w zespole
            </Link>
          </li>
          <li>
            <Link
              title="Zarządzanie uprawnieniami"
              to="/zarzadzanie-uprawnieniami/"
            >
              Zarządzanie uprawnieniami
            </Link>
          </li>
        </ul>
      </div>
    </li>
    <li>
      <Link to="/baza-wiedzy/">Baza wiedzy</Link>
    </li>
    <li>
      <Link to="https://synergiuscrm.pl/crm-cennik">Cennik</Link>
    </li>
    <li>
      <Link to="https://synergiuscrm.pl/o-nas">O nas</Link>
    </li>
    <li>
      <Link to="https://synergiuscrm.pl/kontakt">Kontakt</Link>
    </li>
    <li className="login-link">
      <Link to="/zaloguj-crm/"><strong>Zaloguj</strong></Link>
    </li>
        <li className="login-link" >
      <Link to="https://synergiuscrm.pl/synergius-crm-wersja-demo"><strong>Wypróbuj CRM</strong></Link>
    </li>
  </ul>
);

export default Menu;